<template>
    <Toast />

    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" :disabled="disabled" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <ExclamationTriangleIcon class=" text-orange-600 h-10 w-10 mt-1 mr-1" v-tooltip.bottom="'Speichern nur mit Bild möglich'" v-if="disabled" />
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" :disabled="disabled" />

                <Divider layout="vertical" />
                <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" />
                <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes()" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/4 m-2">
                    <Accordion class="accordion-custom" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2 font-bold">Artikeldaten</span>
                            </template>
                            <div class="my-2">
                                <h3 class="my-1 font-semibold">Beschreibung und Grösse</h3>
                                <Editor v-model="blog.text_content" class="w-full h-28">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                            <div class="my-12">
                                <h3 class="my-1 font-semibold">Materialien und Lieferanten</h3>
                                <Editor v-model="blog.text_composition" class="w-full h-28">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                            <div class="my-12">
                                <h3 class="my-1 font-semibold">Pflegehinweise</h3>
                                <Editor v-model="blog.text_production" class="w-full h-28">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                        </span>
                                    </template>
                                </Editor>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>

                <div class="w-auto md:w-2/4 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>

                                <div class="field col-12 my-7">
                                    <span class="p-float-label">
                                        <InputText id="title" class="w-full" v-model="blog.title" />
                                        <label for="title">Titel</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="price" class="w-full" v-model="blog.price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                        <label for="price">Preis</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="price_eur" class="w-full" v-model="blog.price_eur" inputId="currency-eur" mode="currency" currency="EUR" locale="de-de" :disabled="true" />
                                        <label for="price_eur">Preis (EUR)</label>
                                    </span>
                                    <!-- <Button @click="get_euro_price" label="Preis generieren" v-tooltip.top="''" icon="pi pi-euro" size="small" class="my-1" :loading="euro_loader" :disabled="euro_disabled" /> -->
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="sku" class="w-full" v-model="blog.sku" :disabled="disabled_sku" />
                                        <label for="sku">SKU</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="size" class="w-full" v-model="blog.size" />
                                        <label for="size">Grösse</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="color" class="w-full" v-model="blog.color" />
                                        <label for="color">Farbe</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputNumber id="weight" class="w-full" v-model="blog.weight" :maxFractionDigits="2" locale="en-US" />
                                        <label for="weight">Gewicht</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label_">
                                        <label for="availibility">Verfügbarkeit Status</label>
                                        <InputGroup>
                                            <InputText id="availibility" class="w-full" v-model="blog.availibility" />
                                            <Button icon="pi pi-times" severity="danger" @click="blog.availibility = null" />
                                        </InputGroup>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label_">
                                        <label for="stock">Lagerbestand</label>
                                        <InputText id="stock" class="w-full" :disabled="true" />
                                    </span>
                                </div>

                                <div class="my-7">
                                    <div class="flex align-items-center my-2">
                                        <Checkbox v-model="is_articles_grouped" :binary="true" inputId="is_articles_grouped" />
                                        <label for="is_articles_grouped" class="ml-2">Gruppen</label>
                                    </div>
                                    <span class="p-float-labe" v-show="is_articles_grouped">

                                        <Dropdown v-model="selected_group_article" :options="articles" optionLabel="title" optionValue="sku" placeholder="Artikel wählen" class="w-full mt-2" filter />

                                        <Button :disabled="!is_articles_grouped" @click="add_group" v-tooltip.bottom="'Gruppen Artikel hinzufügen'" label="Artikel hinzufügen" class="mt-2 w-auto p-button" icon="pi pi-plus" />

                                        <DataTable :value="articles_grouped" editMode="cell" @cell-edit-complete="on_group_row_edit" responsiveLayout="scroll" class="w-full" :rowHover="true">
                                            <Column field="sku" header="SKU">
                                                <template #body="slotProps">
                                                    <div>{{ slotProps.data.sku }}</div>
                                                    <div class="text-sm">{{ get_article_name(slotProps.data.sku) }}</div>
                                                </template>
                                            </Column>
                                            <Column field="count" header="Anzahl">
                                                <template #editor="{ data, field }">
                                                    <InputNumber v-model="data[field]" :inputStyle="{ 'width': '50px' }" />
                                                </template>
                                                <template #body="{ data, field }">
                                                    <span>{{ data[field] }}</span>
                                                </template>
                                            </Column>
                                            <Column>
                                                <template #body="slotProps">
                                                    <Button @click="delete_group(slotProps)" v-tooltip.top="'Verpackungseinheit löschen'" rounded class="bg-red-600 text-white border-red-700" icon="pi pi-trash" />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </span>
                                </div>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">
                                    <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image" :aspect-ratio="{ aspectRatio: 1 / 1 }" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>

                                <ImageGallery :image-library="image_library" @update:imageLibrary="image_library = $event" />
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="field col-12 mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(blog_changes.length) }}</span>
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes.sort((a, b) => b.date - a.date)" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">
                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>

    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="field col-12 m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper" :stencil-props="{ aspectRatio: 3 / 2 }" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="field col-12 mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <cropper ref="title_image_cropper_preview" :stencil-props="{ aspectRatio: 1 / 1 }" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="field col-12 mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, onBeforeMount } from 'vue'
import { EnvelopeIcon, ArchiveBoxIcon, CloudIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import InputGroup from 'primevue/inputgroup';
import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, checkDuplicate, preview_dataset, slugify } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button2.vue';
import ImageGallery from '@/core/components/Components/Main-Image-Library.vue'

const props = defineProps({
    redis_key: { type: String }
})

const content_blocks = ref(['standard_text',
    'standard_title',
    'standard_image',
    'cta_button',
    'html_block',
    'quote_block',
    'image_text',
    'comparsion_block',
    'quoting_logo'])

const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")

const blog = ref([])
const blog_categories = ref([])
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(true)
const disabled_sku = ref(false)
const articles = ref()
const articles_grouped = ref([])
const is_articles_grouped = ref(false)
const selected_group_article = ref()
const euro_loader = ref(false)
const euro_disabled = ref(true)

const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    {
        label: 'Vorschau',
        icon: 'pi pi-eye',
        command: () => {
            preview()
        }
    },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);

const MODULE = 'articles';

const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `${MODULE}/categories`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`
}
provide('upload_endpoint', endpoints.uploadsingle);

onBeforeMount(async () => {
    loader.value = true;
    await getBlogs();
    await bootstrap();
    loader.value = false;
})

watch(loader, res => {
    Simplestore.spinner = res;
});

watch(() => blog.value.price, (newVal) => {
    blog.value.price_eur = newVal;
});

watch(workflow_id, res => {
    provide('workflow_id', res)
})

watch(() => blog.value.price, (newPrice) => {
    if(newPrice !== undefined){
        euro_disabled.value = false
    }
});

const bootstrap = async () => {
    try {
        const systemRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`);
        const usersRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getusers}`);
        const categoriesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`);
        const articlesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`);

        const [systemResponse, usersResponse, categoriesResponse, articlesResponse] = await Promise.all([systemRequest, usersRequest, categoriesRequest, articlesRequest]);

        articles.value = articlesResponse.data;

        blog_categories.value = categoriesResponse.data;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data;
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};


const on_group_row_edit = (e) => {
    if (articles_grouped.value[e.index][e.field] != e.newValue) {
        articles_grouped.value[e.index][e.field] = e.newValue
    }
}

const delete_group = (e) => {
    confirm.require({
        message: 'Wollen Sie wirklich die Gruppen Artikel ' + e.data.sku + ' löschen?',
        header: 'Gruppen Artikel löschen',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Gruppen Artikel löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            articles_grouped.value.splice(e.index, 1)
        }
    })
}

const add_group = () => {
    if (!articles_grouped.value) {
        articles_grouped.value = []
    }

    if (selected_group_article.value) {
        articles_grouped.value.push({ sku: selected_group_article.value, count: 1 })
    }
}

const get_article_name = (sku) => {

    if (articles.value == undefined) {
        return
    }

    const article = articles.value.find(x => x.sku == sku)

    if (article !== undefined)
        return article.title
}

const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;

        articles_grouped.value = data.group_articles
        is_articles_grouped.value = articles_grouped.value.length > 0 ? true : false
        GPT_Primer(blog_title.value);

    } catch (error) {
        console.error("Error fetching article data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = res => {
    blog.value.text_content = blog.value.text_content + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const close_tab = () => {
    confirm.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

const showGallery = (index) => {
    activeImageIndex.value = index
    displayGallery.value = true
}

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = async () => {
    loader.value = true;
    const { value } = blog;

    if (is_articles_grouped.value !== true){
            if ((value.price == '' || value.price == undefined)) {
                toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen Preis an', life: 3000 })
                loader.value = false;
                return;
            }

        if (value.price_eur == '' || value.price_eur == undefined) {
            toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie einen EUR Preis an', life: 3000 })
            loader.value = false;
            return;
        }
    }else{
        toast.add({ severity: 'info', summary: 'Info', detail: 'Gruppierter Artikel', life: 3000 })
    }
    if (value.color == '' || value.color == undefined) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte geben Sie eine Farbe an', life: 3000 })
        loader.value = false;
        return;
    }

    if (value.sku == '' || value.sku == undefined) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'SKU darf nicht leer sein', life: 3000 })
        loader.value = false;
        return;
    }

    const checkSKU = await checkDuplicate(blog.value.sku, blog.value.workflow_id, 'swisswool/articles/check-sku')

    if (checkSKU.msg == true) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der SKU existiert bereits', life: 3000 })
        loader.value = false;
        return;
    }

    value["content"] = blog_content.value
    value["changes"] = blog_changes.value
    value["image_library"] = image_library.value

    console.log(articles_grouped.value)

    if (is_articles_grouped.value == true) {
        value['group'] = is_articles_grouped.value
        value['group_articles'] = articles_grouped.value
    } else {
        value['group'] = false
        value['group_articles'] = false
    }

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { key } = response.data;

        toast.add({
            severity: "success",
            summary: `${value.title} gespeichert`,
            detail: "Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        blog.value.redis_key = key
        loader.value = false;
        disabled_sku.value = true

    } catch (error) {
        console.error(error);
    }
};

const setRedisKey = (slug, mdle) => {
    return `${channel.value}:${mdle}:${slug}`
}

const reset_changes = async () => {
    confirm.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};


const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        blog_content.value = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}


const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}

const libraryUploadGetData = (res) => {
    blog.value['image_library'].push({ image: res });
}

watch(blog, res => {
    if (res.header_image.length > 0) {
        disabled.value = false;
    }
})

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {

    editTitleImageDialog.value = res;
    console.log(editTitleImageDialog.value);
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
    disabled.value = false;
}

const delete_image = res => {
    blog.value[res.type] = false;
    disabled.value = false;
}
const get_euro_price = async () => {

    euro_loader.value = true
    const euro_price = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/articles/get-euro-price`,{price: blog.value.price});
    blog.value.price_eur = euro_price.data
    euro_loader.value = false
    toast.add({ severity: 'info', summary: 'Euro Preis aktualisiert', detail: 'Euro-Preis wurde erfolgreich aktualisiert', life: 3000 })
}
</script>
