/** 
 * Index for default customer 
*/
/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createApp} from 'vue'
import App from '@/App.vue'
import CoreRouter from '@/core/router/router';


import {
    Square3Stack3DIcon
} from '@heroicons/vue/24/outline';

import SwisstoolTeam from './components/Swisswool-Team.vue'
import SwisswoolFAQ from './components/Swisswool-FAQ.vue'
import SwisswoolPromocodes from './components/Swisswool-Promocodes.vue'
import SwisswoolMengenrabatt from './components/Swisswool-Discount.vue'
import SwisswoolProduktwelt from './components/Swisswool-Produktwelt-Tab.vue'
import SwisswoolStories from './components/Swisswool-Stories.vue'
import SwisswoolPartners from './components/Swisswool-Partners.vue'
import SwisswoolProdukte from './components/Swisswool-Produkte-Tab.vue'
import SwisswooWollanahmen from './components/Swisswool-Wollanahmen.vue'
import SwisswoolSettings from '@/core/components/User/Admin/Admin-User-Settings.vue'
import SwisswoolInvite from './components/Swisswool-Invite.vue'
import SwisswoolOrders from './components/Swisswool-Orders.vue'
import SwisswoolWarehouse from './components/Swisswool-Warehouse.vue'
import DataHub from '@/core/components/User/Admin/Admin-Datahub.vue';

import {
    RouteAdd,
    RouteAddtoDashboard,
    DashboardAddLink
} from '@/core/var/router';

const customer = require('./config/config');
import '@/core/var/axios';
import { createPinia } from 'pinia'; const pinia = createPinia();
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import '@/core/assets/primevue/neuraxis.css';
//import '/node_modules/primevue/resources/themes/aura-light-blue/theme.css'


import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import InputMask from 'primevue/inputmask'
import Toast from 'primevue/toast'
import Breadcrumb from 'primevue/breadcrumb'
import Menubar from 'primevue/menubar'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Panel from 'primevue/panel'
import OrderList from 'primevue/orderlist'
import AutoComplete from 'primevue/autocomplete'
import OverlayPanel from 'primevue/overlaypanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Fieldset from 'primevue/fieldset'
import Divider from 'primevue/divider'
import Card from 'primevue/card'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import ProgressSpinner from 'primevue/progressspinner'
import BlockUI from 'primevue/blockui'
import Chips from 'primevue/chips'
import StyleClass from 'primevue/styleclass'
import Textarea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import TreeSelect from 'primevue/treeselect'
import InputSwitch from 'primevue/inputswitch'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Chip from 'primevue/chip'
import Checkbox from 'primevue/checkbox'
import Tag from 'primevue/tag'
import SpeedDial from 'primevue/speeddial'
import Menu from 'primevue/menu'
import Sidebar from 'primevue/sidebar'
import Message from 'primevue/message'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import ConfirmDialog from 'primevue/confirmdialog'
import FileUpload from 'primevue/fileupload'
import ToggleButton from 'primevue/togglebutton'
import TreeTable from 'primevue/treetable'
import Editor from 'primevue/editor'
import SplitButton from 'primevue/splitbutton'
import Toolbar from 'primevue/toolbar'
import Galleria from 'primevue/galleria'





const data_hub_link = 'datahub';
RouteAdd(data_hub_link, DataHub, CoreRouter);
//RouteAddtoDashboard({ name: 'User-Add', position: 'dashboard-left' }, AdminUseradd, CoreRouter);
RouteAddtoDashboard({ name: 'Team', position: 'dashboard-left' }, SwisstoolTeam, CoreRouter);
RouteAddtoDashboard({ name: 'Bestellungen', position: 'dashboard-left' }, SwisswoolOrders, CoreRouter);
RouteAddtoDashboard({ name: 'Storys', position: 'dashboard-left' }, SwisswoolStories, CoreRouter);
RouteAddtoDashboard({ name: 'Partner', position: 'dashboard-left' }, SwisswoolPartners, CoreRouter);
RouteAddtoDashboard({ name: 'Produktwelt', position: 'dashboard-left' }, SwisswoolProduktwelt, CoreRouter);
RouteAddtoDashboard({ name: 'Einstellungen', position: 'dashboard-left' }, SwisswoolSettings, CoreRouter);
RouteAddtoDashboard({ name: 'Produkte', position: 'dashboard-left' }, SwisswoolProdukte, CoreRouter);
RouteAddtoDashboard({ name: 'Wollannahmen', position: 'dashboard-left' }, SwisswooWollanahmen, CoreRouter);
RouteAddtoDashboard({ name: 'FAQ', position: 'dashboard-left' }, SwisswoolFAQ, CoreRouter);
RouteAddtoDashboard({ name: 'Promocodes', position: 'dashboard-left' }, SwisswoolPromocodes, CoreRouter);
RouteAddtoDashboard({ name: 'Mengenrabatt', position: 'dashboard-left' }, SwisswoolMengenrabatt, CoreRouter);
RouteAddtoDashboard({ name: 'Lager', position: 'dashboard-left' }, SwisswoolWarehouse, CoreRouter);

DashboardAddLink({ name: 'Data Hub', href: `/${data_hub_link}`, icon: Square3Stack3DIcon, order: 10 });
const swisswool_invite_link = 'invite'
RouteAdd(swisswool_invite_link, SwisswoolInvite, CoreRouter)

const neuraxisApp = createApp(App);

//const SERVICE_URL = process.env.NODE_ENV !== 'development' ? ' https://swisswool-ng-lwnjocwfda-oa.a.run.app/preview' : 'http://localhost:3000/preview'
const SERVICE_URL =  'https://swisswool.ch/preview'
neuraxisApp.provide('SERVICE_URL', SERVICE_URL);

neuraxisApp.use(pinia);
const customerStore = useCustomerStore();
customerStore.setCustomer(customer);

const auth = useAuthStore();

neuraxisApp.use(CoreRouter);

//optional
neuraxisApp.use(PrimeVue, { ripple: true });
neuraxisApp.use(ToastService);
neuraxisApp.use(ConfirmationService);
neuraxisApp.directive('Tooltip', Tooltip);
neuraxisApp.directive('badge', BadgeDirective);
neuraxisApp.directive('ripple', Ripple);
neuraxisApp.directive('styleclass', StyleClass);

neuraxisApp.component('Dialog', Dialog);
neuraxisApp.component('InputText', InputText)
neuraxisApp.component('Button', Button)
neuraxisApp.component('Toast', Toast)
neuraxisApp.component('ConfirmDialog', ConfirmDialog)
neuraxisApp.component('Breadcrumb', Breadcrumb)
neuraxisApp.component('Menubar', Menubar)
neuraxisApp.component('Splitter', Splitter)
neuraxisApp.component('SplitterPanel', SplitterPanel)
neuraxisApp.component('Panel', Panel)
neuraxisApp.component('OrderList', OrderList)
neuraxisApp.component('AutoComplete', AutoComplete)
neuraxisApp.component('OverlayPanel', OverlayPanel)
neuraxisApp.component('DataTable', DataTable)
neuraxisApp.component('Column', Column)
neuraxisApp.component('Fieldset', Fieldset)
neuraxisApp.component('Divider', Divider)
neuraxisApp.component('Card', Card)
neuraxisApp.component('Badge', Badge)
neuraxisApp.component('Dropdown', Dropdown)
neuraxisApp.component('MultiSelect', MultiSelect)
neuraxisApp.component('ProgressSpinner', ProgressSpinner)
neuraxisApp.component('BlockUI', BlockUI)
neuraxisApp.component('InputNumber', InputNumber)
neuraxisApp.component('Chips', Chips)
neuraxisApp.component('Textarea', Textarea)
neuraxisApp.component('Calendar', Calendar)
neuraxisApp.component('TreeSelect', TreeSelect)
neuraxisApp.component('InputSwitch', InputSwitch)
neuraxisApp.component('TabView', TabView)
neuraxisApp.component('TabPanel', TabPanel)
neuraxisApp.component('Avatar', Avatar)
neuraxisApp.component('AvatarGroup', AvatarGroup)
neuraxisApp.component('Chip', Chip)
neuraxisApp.component('Checkbox', Checkbox)
neuraxisApp.component('InputMask', InputMask)
neuraxisApp.component('Tag', Tag)
neuraxisApp.component('SpeedDial', SpeedDial)
neuraxisApp.component('Menu', Menu)
neuraxisApp.component('Sidebar', Sidebar)
neuraxisApp.component('Message', Message)
neuraxisApp.component('Accordion', Accordion)
neuraxisApp.component('AccordionTab', AccordionTab)
neuraxisApp.component('RadioButton', RadioButton)
neuraxisApp.component('SelectButton', SelectButton)
neuraxisApp.component('FileUpload', FileUpload)
neuraxisApp.component('ToggleButton', ToggleButton)
neuraxisApp.component('TreeTable', TreeTable)
neuraxisApp.component('Editor', Editor)
neuraxisApp.component('SplitButton', SplitButton)
neuraxisApp.component('Toolbar', Toolbar)
neuraxisApp.component('Galleria', Galleria)

neuraxisApp.mount('#app');