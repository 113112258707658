<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref([])
const details = ref()
const search_term = ref('')
const result_count = ref(200)
const orderDetailsDialog = ref(false)

onMounted(() => {
    get_orders()
})

const get_orders = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/swisswool/get-orders')
    .then(response => {
        orders.value = response.data
        loader.value = false
    })
}

const order_details = (e) => {
    console.log(e.data)
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/swisswool/order-details', {order_id: e.data.id})
    .then(response => {
        console.log("response.data", response.data)
        details.value = response.data
        orderDetailsDialog.value = true
        loader.value = false
    })
}

const new_order = () => {
    console.log("new_order")
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const open_bexio_customer = (id) => {
    window.open('https://office.bexio.com/index.php/kontakt/show/id/' + String(id),'_blank')
}

const open_bexio_order = (id) => {
    window.open('https://office.bexio.com/index.php/kb_order/show/id/' + String(id),'_blank')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_orders['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                    </span>
                </template>
                <template #end>
                    <Button label="Neue Bestellung" icon="pi pi-plus" class="p-button bg-green-600 text-white border-green-700" @click="new_order" />
                </template>
            </Toolbar>
        </div>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <DataTable v-model:filters="filter_orders" :value="orders" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100, 200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="document_nr" header="Bestellung" sortable>
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-shopping-cart" /></span>
                            <span class="ml-2 font-medium">{{slotProps.data.document_nr}}</span>
                        </Chip>
                        <span class="font-bold mr-2">{{slotProps.data.contact_address.split("\n")[0]}}</span>
                    </template>
                </Column>
                <Column field="is_valid_from" header="Datum" sortable>
                    <template #body="slotProps">
                        <span>{{ format_swiss_date(slotProps.data.is_valid_from) }}</span>
                    </template>
                </Column>
                <Column field="total" header="Betrag" sortable>
                    <template #body="slotProps">
                        <span>{{ format_currency(slotProps.data.total) }}</span>
                    </template>
                </Column>
                <Column field="kb_item_status_id" header="Status" sortable>
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.kb_item_status_id == 6" value="Abgeschlossen" class="mr-2 bg-green-600 text-white" />
                        <Badge v-if="slotProps.data.kb_item_status_id == 5" value="Offen" class="mr-2 bg-primary-500 text-black" />
                        <Badge v-if="slotProps.data.kb_item_status_id == 15" value="Teilweise" class="mr-2 bg-orange-500 text-white" />
                    </template>
                </Column>
                <Column hidden field="api_reference" />
                <Column hidden field="contact_address" />
                <Column>
                    <template #body="slotProps">
                        <Button label="Bexio" @click="open_bexio_order(slotProps.data.id)" class="w-auto mr-2 mb-1 p-button" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>

</template>