<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_stocks = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_logs = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const stocks = ref([])
const logs = ref([])

onMounted(() => {
    get_stocks()
    get_logs()
})

const get_stocks = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/swisswool/get-stocks')
    .then(response => {
        stocks.value = response.data
        loader.value = false
    })
}

const get_logs = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/swisswool/get-stock-logs')
    .then(response => {
        logs.value = response.data
        loader.value = false
    })
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const open_bexio_product = (id) => {
    window.open('https://office.bexio.com/index.php/artikel/show/id/' + String(id),'_blank')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
    <div class="p-fluid formgrid grid">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
            <template #header>
                <i class="pi pi-table mr-2"></i>
                <span class="mr-3">Lager</span>
            </template>
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_stocks['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                    </span>
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
                <DataTable v-model:filters="filter_stocks" :value="stocks" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100, 200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                    <Column field="intern_code" header="Produkt" sortable>
                        <template #body="slotProps">
                            <Chip class="pl-0 pr-3 mr-2">
                                <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-shopping-cart" /></span>
                                <span class="ml-2 font-medium">{{slotProps.data.intern_code}}</span>
                            </Chip>
                            <span class="font-bold mr-2">{{slotProps.data.intern_name}}</span>
                        </template>
                    </Column>
                    <Column field="sale_price" header="Preis" sortable>
                        <template #body="slotProps">
                            <span>{{format_currency(slotProps.data.sale_price)}}</span>
                        </template>
                    </Column>
                    <Column field="stock_available_nr" header="Bexio" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.stock_available_nr}}</span>
                        </template>
                    </Column>
                    <Column field="stock_sg_ch" header="SG CH" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.stock_sg_ch}}</span>
                        </template>
                    </Column>
                    <Column field="stock_sg_de" header="SG DE" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.stock_sg_de}}</span>
                        </template>
                    </Column>
                    <Column field="stock_available_nr" header="Diff." sortable>
                        <template #body="slotProps">
                            <div v-if="slotProps.data.stock_sg_ch">
                                <template v-if="(slotProps.data.stock_sg_ch - slotProps.data.stock_available_nr) == 0">
                                    <span class="font-bold text-green-500">{{slotProps.data.stock_sg_ch - slotProps.data.stock_available_nr}}</span>
                                </template>
                                <template v-if="(slotProps.data.stock_sg_ch - slotProps.data.stock_available_nr) != 0">
                                    <span class="font-bold text-red-500">{{slotProps.data.stock_sg_ch - slotProps.data.stock_available_nr}}</span>
                                </template>
                            </div>
                            <div v-if="slotProps.data.stock_sg_de">
                                <template v-if="(slotProps.data.stock_sg_de - slotProps.data.stock_available_nr) == 0">
                                    <span class="font-bold text-green-500">{{slotProps.data.stock_sg_de - slotProps.data.stock_available_nr}}</span>
                                </template>
                                <template v-if="(slotProps.data.stock_sg_de - slotProps.data.stock_available_nr) != 0">
                                    <span class="font-bold text-red-500">{{slotProps.data.stock_sg_de - slotProps.data.stock_available_nr}}</span>
                                </template>
                            </div>
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button label="Bexio" @click="open_bexio_product(slotProps.data.id)" class="w-auto mr-2 mb-1 p-button" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-table mr-2"></i>
                    <span class="mr-3">Bewegungen</span>
                </template>
                <div class="col-12 mb-2">
                    <Toolbar>
                        <template #start>
                            <span class="p-input-icon-left mr-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filter_logs['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                            </span>
                        </template>
                    </Toolbar>
                    <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
                        <DataTable v-model:filters="filter_logs" :value="logs" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100, 200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                            <Column field="date" header="Datum" sortable>
                                <template #body="slotProps">
                                    <span>{{format_swiss_date(slotProps.data.date)}}</span>
                                </template>
                            </Column>
                            <Column field="intern_code" header="Produkt" sortable>
                                <template #body="slotProps">
                                    <span class="font-bold">{{slotProps.data.intern_code}}</span>
                                </template>
                            </Column>
                            <Column field="stock" header="Vorher" sortable>
                                <template #body="slotProps">
                                    <span class="font-bold text-red-500">{{slotProps.data.stock}}</span>
                                </template>
                            </Column>
                            <Column field="stock_new" header="Neu" sortable>
                                <template #body="slotProps">
                                    <span class="font-bold text-green-500">{{slotProps.data.stock_new}}</span>
                                </template>
                            </Column>
                            <Column field="mandant" header="Lager" sortable>
                                <template #body="slotProps">
                                    <span class="font-bold" v-if="slotProps.data.mandant == '16790'">CH</span>
                                    <span class="font-bold" v-if="slotProps.data.mandant == '16791'">DE</span>
                                </template>
                            </Column>
                            <Column field="filename" header="Datei" sortable>
                                <template #body="slotProps">
                                    <span>{{slotProps.data.filename}}</span>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </div>
    </BlockUI>

</template>